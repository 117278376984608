<template>
  <div class="pageContol live">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">直播落地页</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ this.ruleForm.courseId?"编辑":"新增"}}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              > 
                <h3 class="Underline">直播信息</h3>
                <el-form-item label="栏目标题：" prop="columnTitle">
                  <el-input
                    v-model="ruleForm.columnTitle"
                    size="small"
                    maxlength="100"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="直播名称：" prop="broadcastName">
                  <el-input
                    v-model="ruleForm.broadcastName"
                    size="small"
                    show-word-limit
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <el-form-item label="直播封面：" prop="broadcastCover">
                  <el-upload
                    :on-change="handleAvatarSuccess1"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.broadcastCover || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                    <div
                      slot="tip"
                      style="margin-top: 0px"
                      class="el-upload__tip"
                    >
                      建议上传尺寸&nbsp;700*422
                    </div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="直播时间：" prop="broadcastTime">
                  <el-date-picker
                    size="small"
                    v-model="ruleForm.broadcastTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
                <h3 class="Underline">内容简介</h3>
                <el-form-item label="栏目标题：" prop="contentTitle">
                  <el-input
                    v-model="ruleForm.contentTitle"
                    size="small"
                    show-word-limit
                    placeholder="请输入栏目标题"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <el-form-item label="内容：" prop="contextFee">
                  <div
                    ref="editorFee"
                    class="editor"
                    style="width: 100%; max-width: 1200px"
                  />
                </el-form-item>
                <h3 class="Underline">师资简介</h3>
                <el-form-item label="栏目标题：" prop="teacherTitle">
                  <el-input
                    v-model="ruleForm.teacherTitle"
                    size="small"
                    show-word-limit
                    placeholder="请输入栏目标题"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <!-- 讲师头像 -->
                <el-form-item label="讲师头像：" prop="teacherHeadCover">
                  <el-upload
                    :on-change="handleAvatarSuccess4"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.teacherHeadCover || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                    <div
                      slot="tip"
                      style="margin-top: 0px"
                      class="el-upload__tip"
                    >
                      建议上传尺寸&nbsp;50*50
                    </div>
                  </el-upload>
                </el-form-item>
                <!-- 头像底图 -->
                <el-form-item label="头像底图：" class="headSculptureCover" prop="headSculptureCover">
                  <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.headSculptureCover || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                    <div
                      slot="tip"
                      style="margin-top: 0px"
                      class="el-upload__tip"
                    >
                      建议上传尺寸&nbsp;700*422
                    </div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="讲师名称：" prop="teacherName">
                  <el-input
                    v-model="ruleForm.teacherName"
                    size="small"
                    show-word-limit
                    maxlength="10"
                  ></el-input>
                </el-form-item>
                <el-form-item label="讲师单位：" prop="teacherCompany">
                  <el-input
                    v-model="ruleForm.teacherCompany"
                    size="small"
                    show-word-limit
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <el-form-item label="讲师职称：" prop="teacherRank">
                  <el-input
                    v-model="ruleForm.teacherRank"
                    size="small"
                    show-word-limit
                    maxlength="20"
                  ></el-input>
                </el-form-item>
                <el-form-item label="内容：" prop="contextFee1">
                  <div
                    ref="editorFee1"
                    class="editor"
                    style="width: 100%; max-width: 1200px"
                  />
                </el-form-item>
                <!-- 公司简介 -->
                <h3 class="Underline">公司简介</h3>
                <el-form-item label="栏目标题：" prop="companyTitle">
                  <el-input
                    v-model="ruleForm.companyTitle"
                    size="small"
                    show-word-limit
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <el-form-item label="内容：" prop="contextFee2">
                  <div
                    ref="editorFee2"
                    class="editor"
                    style="width: 100%; max-width: 1200px"
                  />
                </el-form-item>
               <el-form-item
                  label="公司照片："
                  prop="fileList"
                  class="bannerBox"
                >
                  <el-upload
                    action
                    :file-list="ruleForm.fileList"
                    list-type="picture-card"
                    :on-change="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload2"
                    :http-request="$requestMine"
                    :on-remove=" (res, file) => {handleRemove(res, file,index)}"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
                <h3 class="Underline">分享海报</h3>
                <el-form-item label="分享海报：" prop="sharePostersCover" class="shareCover">
                  <el-upload
                    :on-change="handleAvatarSuccess3"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.sharePostersCover || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                    <div slot="tip" class="el-upload__tip">
                      建议上传尺寸&nbsp;558*716
                    </div>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="cancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >确 定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
let editoreFee = {};
let editoreFee1 = {};
let editoreFee2 = {};
export default {
  name: "liveBroadcastLanding/liveBroadcastLandingPageEditing",
  components: {},
  data() {
    return {

      //校验规则
      rules: {
         columnTitle: [
          {
            required: true,
            trigger: "blur",
            message: "请输入栏目标题",
          }
        ],
          broadcastName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入直播名称",
          }, 
        ],
        broadcastCover: [
          {
            required: true,
            trigger: "change",
            message: "请上传直播图片",
          }, 
        ],
        broadcastTime: [
          {
            required: true,
            trigger: "change",
            message: "请选择直播时间",
          }, 
        ],
        contentTitle: [
          {
            required: true,
            trigger: "blur",
            message: "请输入栏目标题",
          }, 
        ],
        contextFee: [
          {
            required: true,
            trigger: "blur",
            message: "请输入内容简介",
          }, 
        ],
        teacherTitle: [
          {
            required: true,
            trigger: "blur",
            message: "请输入栏目标题",
          }, 
        ],
        teacherHeadCover: [
          {
            required: true,
            trigger: "change",
            message: "请上传头像",
          }, 
        ],
        headSculptureCover: [
          {
            required: true,
            trigger: "change",
            message: "请上传头像底图",
          }, 
        ],
        teacherName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入讲师名称",
          }, 
        ],
        teacherRank: [
          {
            required: true,
            trigger: "blur",
            message: "请输入讲师职称",
          }, 
        ],
        teacherCompany: [
          {
            required: true,
            trigger: "blur",
            message: "请输入讲师单位",
          }, 
        ],
        companyTitle: [
          {
            required: true,
            trigger: "blur",
            message: "请输入栏目标题",
          }, 
        ],
        fileList:[
          {
            required: true,
            trigger: "change",
            message: "请上传公司照片",
          }, 
        ],
        sharePostersCover: [
          {
            required: true,
            trigger: "change",
            message: "请上传分享海报",
          }, 
        ],
        contextFee1: [
          {
            required: true,
            trigger: "blur",
            message: "请输入讲师简介",
          }, 
        ],
        contextFee2: [
          {
            required: true,
            trigger: "blur",
            message: "请输入公司简介",
          }, 
        ],

      },
      ruleForm: {
        courseId: "", //课程id 修改时候传入
        columnTitle: "", //直播栏目标题
        broadcastName: "", //直播名称
        broadcastCover: "", //直播图片地址
        broadcastCoverfileKey: "", //直播图片k值
        broadcastTime: [], //直播时间
        contentTitle: "", //内容简介栏目标题
        teacherTitle: "", //师资栏目标题
        teacherHeadCover:"",//讲师头像
        teacherHeadCoverfileKey:"",//讲师头像k值
        headSculptureCover: "", //头像底图地址
        headSculptureCoverfileKey: "", //头像底图k值
        teacherName: "", //讲师名称
        teacherRank: "", //讲师职称
        teacherCompany:"",//讲师单位
        companyTitle: "", //公司简介栏目标题
        sharePostersCover: "", //分享海报地址
        sharePostersCoverfileKey: "", //分享海报k值
        contextFee: "", //富文本
        contextFee1: "", //讲师富文本1
        contextFee2: "", //公司富文本2
        thumbnail:[],//公司照片
        fileList: [],//公司文件列表
        hideUploadCard:false
      },
    };
  },
  watch: {
    

  },
  created() {
    this.ruleForm.courseId = this.$route.query.courseId; //当为编辑时 传过来的课程id
    if(this.ruleForm.courseId){
      
      this.getDetail(this.ruleForm.courseId);
    }
  },
  mounted() {
    this.editorInit();
    this.editorInit1();
    this.editorInit2();
  },
  computed: {},
  methods: {
    //取消
    cancel(){
      this.$router.push({
        path: "/web/miniApp/liveBroadcastLanding",
         query: {
            refresh: true,
          },

      });
    },
    //回显
    getDetail(courseId) {
      this.$post(
        "/course/live/share/detail",
        { courseId },
        3000,
        true,
        2
      ).then((ret) => {
        //照片地址
        this.ruleForm.broadcastCover=ret.data.courseLogoUrl;//直播logo地址
        this.ruleForm.teacherHeadCover=ret.data.lecturerHeadUrl;//教师头像地址
        this.ruleForm.headSculptureCover=ret.data.lecturerBackUrl;//教师头像底图
        this.ruleForm.sharePostersCover=ret.data.sharePhotoUrl;//分享海报
        for (var i = 0; i < ret.data.companyPhotoUrls.length; i++) {
          this.ruleForm.fileList.push({url:ret.data.companyPhotoUrls[i]});
        }
        //直播信息模块
        this.ruleForm.columnTitle=ret.data.liveModuleName;
        // this.ruleForm.broadcastName=ret.data.liveModuleName;
        this.ruleForm.broadcastName=ret.data.courseName;
        this.ruleForm.broadcastCoverfileKey=ret.data.miniPageIndex;
        this.ruleForm.broadcastTime=[ret.data.courseStartTime.replaceAll('/','-'),ret.data.courseEndTime.replaceAll('/','-')];

        //直播内容模块
        this.ruleForm.contentTitle=ret.data.liveContentModuleName;
        
        this.ruleForm.contextFee=ret.data.courseIntro;
        editoreFee.txt.html(ret.data.courseIntro);
        this.ruleForm.broadcastCoverfileKey=ret.data.courseLogo;
        //讲师模块
        this.ruleForm.teacherTitle=ret.data.lecturerModuleName;
        this.ruleForm.teacherHeadCoverfileKey=ret.data.lecturerHead;
        this.ruleForm.headSculptureCoverfileKey=ret.data.lecturerBack;
        this.ruleForm.teacherName=ret.data.lecturerName;
        this.ruleForm.teacherRank=ret.data.lecturerPost;
        this.ruleForm.contextFee1=ret.data.lectureIntro;
        editoreFee1.txt.html(ret.data.lectureIntro);
        this.ruleForm.teacherCompany=ret.data.lecturerCompany;
        //公司模块
        this.ruleForm.companyTitle=ret.data.companyModuleName;
        this.ruleForm.contextFee2=ret.data.companyIntro;
        editoreFee2.txt.html(ret.data.companyIntro);
        //分享海报
        this.ruleForm.sharePostersCoverfileKey=ret.data.sharePhoto;
        //照片墙k值
        this.ruleForm.thumbnail=ret.data.companyPhotos.split(',');
    }).catch(() => {
              return;
            });
  },
    // 富文本
    editorInit() {
      editoreFee = new E(this.$refs.editorFee);
      editoreFee.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editoreFee.config.zIndex = 100;
      editoreFee.config.uploadImgShowBase64 = true;
      editoreFee.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editoreFee.config.pasteFilterStyle = "text";
      editoreFee.config.pasteIgnoreImg = true;

      editoreFee.config.onchange = (html) => {
        this.ruleForm.contextFee = html;
      };
      editoreFee.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editoreFee.create();
    },
    // 富文本1
    editorInit1() {
      editoreFee1 = new E(this.$refs.editorFee1);
      editoreFee1.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editoreFee1.config.zIndex = 100;
      editoreFee1.config.uploadImgShowBase64 = true;
      editoreFee1.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editoreFee1.config.pasteFilterStyle = "text";
      editoreFee1.config.pasteIgnoreImg = true;

      editoreFee1.config.onchange = (html) => {
        this.ruleForm.contextFee1 = html;
      };
      editoreFee1.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editoreFee1.create();
    },
    // 富文本2
    editorInit2() {
      editoreFee2 = new E(this.$refs.editorFee2);
      editoreFee2.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editoreFee2.config.zIndex = 100;
      editoreFee2.config.uploadImgShowBase64 = true;
      editoreFee2.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editoreFee2.config.pasteFilterStyle = "text";
      editoreFee2.config.pasteIgnoreImg = true;

      editoreFee2.config.onchange = (html) => {
        this.ruleForm.contextFee2 = html;
      };
      editoreFee2.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editoreFee2.create();
    },
    doAddSave(formName) {
      const params = {
        //直播信息模块
        courseName:this.ruleForm.broadcastName,
        // liveModuleName:this.ruleForm.broadcastName,
        liveModuleName:this.ruleForm.columnTitle,
        miniPageIndex:this.ruleForm.broadcastCoverfileKey,
        courseStartTime:this.ruleForm.broadcastTime[0],
        courseEndTime:this.ruleForm.broadcastTime[1],
        //直播内容模块
        liveContentModuleName:this.ruleForm.contentTitle,
        courseLogo:this.ruleForm.broadcastCoverfileKey,
        // courseName:this.ruleForm.contentTitle,
        
        courseIntro:this.ruleForm.contextFee,
        //讲师模块
        lecturerModuleName:this.ruleForm.teacherTitle,
        lecturerHead:this.ruleForm.teacherHeadCoverfileKey,
        lecturerBack:this.ruleForm.headSculptureCoverfileKey,
        lecturerName:this.ruleForm.teacherName,
        lecturerPost:this.ruleForm.teacherRank,
        lectureIntro:this.ruleForm.contextFee1,
        lecturerCompany:this.ruleForm.teacherCompany,
        //公司模块
        companyModuleName:this.ruleForm.companyTitle,
        companyIntro:this.ruleForm.contextFee2,
        companyPhotos:this.ruleForm.thumbnail.join(","),
        //分享海报
        sharePhoto:this.ruleForm.sharePostersCoverfileKey,
      };
      if(this.ruleForm.courseId){
        params.courseId=this.ruleForm.courseId;
      }
      if(this.ruleForm.broadcastTime.length>0){
        if(this.ruleForm.broadcastTime[0].slice(0,10)!=this.ruleForm.broadcastTime[1].slice(0,10)){
        this.$message.error("直播开始时间和结束时间请选择同一天！");
        return false;
        }
      }
      if(editoreFee.txt.text()==''){
        this.$message.error("请输入直播简介内容");
        return false
      }
      if(editoreFee1.txt.text()==''){
        this.$message.error("请输入师资简介内容");
        return false
      }
      if(editoreFee2.txt.text()==''){
        this.$message.error("请输入公司简介内容");
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            "/course/live/share/save",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (!this.ruleForm.courseId) {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                  this.cancel();
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                  this.cancel();
                }
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //直播封面上传
    handleAvatarSuccess1(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.broadcastCover = result.data.fileURL || "";
            this.ruleForm.broadcastCoverfileKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 头像底图上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.headSculptureCover = result.data.fileURL || "";
            this.ruleForm.headSculptureCoverfileKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    /* 分享海报 */
    //图片上传
    handleAvatarSuccess3(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.sharePostersCover = result.data.fileURL || "";
            this.ruleForm.sharePostersCoverfileKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 头像上传
    handleAvatarSuccess4(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.teacherHeadCover = result.data.fileURL || "";
            this.ruleForm.teacherHeadCoverfileKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    /* 上传图片start 留着 */
    handlePictureCardPreview(res, fileList) {
      this.ruleForm.fileList.push(res);
      const that = this;
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_WARE");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.thumbnail.push(
              result.data.fileKey
            );
          })
          .catch(() => {
            return;
          });
      }
    },
      /* 上传图片end */
    //图片格式判断
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function(event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function() {
          that.ruleForm.width = img.width || 375;
          that.ruleForm.height = img.height || 200;
          file.width = img.width;
          file.height = file.width;
        };
      };
      reader.readAsDataURL(file);

      return isLt2M;
    },
  
    /* 删除图片start */
    handleRemove(res, fileList) {
      let index=this.ruleForm.fileList.indexOf(res);
      this.ruleForm.fileList=fileList;
      this.ruleForm.thumbnail.splice(index,1);
    },
  },
};
</script>
<style lang="less" scoped>
.Underline {
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(208, 208, 208);
  margin-bottom: 10px;
}
.bannerBox /deep/.el-upload-list--picture-card .el-upload-list__item-thumbnail {
      position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.bannerBox /deep/.el-upload-list__item .el-icon-close-tip {
   
    z-index: 9999;
}
.live /deep/.el-upload {
  width:286px;
}
.shareCover/deep/ .el-upload {
    width:186px;
    height: 268px !important;
  }
</style>
